import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormLabel, FormGroup, FormControl, FormHelperText, FormControlLabel, } from '@mui/material';
export function RHFCheckbox({ name, helperText, ...other }) {
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs("div", { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { ...field, checked: field.value }), ...other }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error?.message : helperText }))] })) }));
}
export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, ...other }) {
    const { control } = useFormContext();
    const getSelected = (selectedItems, item) => selectedItems.includes(item)
        ? selectedItems.filter((value) => value !== item)
        : [...selectedItems, item];
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", sx: { typography: 'body2' }, children: label })), _jsx(FormGroup, { sx: {
                        ...(row && {
                            flexDirection: 'row',
                        }),
                        '& .MuiFormControlLabel-root': {
                            '&:not(:last-of-type)': {
                                mb: spacing || 0,
                            },
                            ...(row && {
                                mr: 0,
                                '&:not(:last-of-type)': {
                                    mr: spacing || 2,
                                },
                            }),
                        },
                    }, children: options.map((option) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: field.value.includes(option.value), onChange: () => field.onChange(getSelected(field.value, option.value)) }), label: option.label, ...other }, option.value))) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { mx: 0 }, children: error ? error?.message : helperText }))] })) }));
}
